<template>
    <div class="wit-checkbox-group" :id="id">
        <b-form-checkbox-group
            v-model="internalValue"
            :options="options"
            size="sm"
            :disabled="disabled"
        ></b-form-checkbox-group>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        id: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            internalValue: null,
        }
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
        internalValue() {
            this.emitInput()
        },
    },
    created() {
        this.internalValue = this.value
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },
    },
}
</script>

<style>
.wit-checkbox-group {
    margin-top: 5px;
    margin-bottom: -5px;
}
</style>
